import React, { useState } from 'react'
import './newenquire.css'

function Newenqire() {
    const [name, setName] = useState("")
    const [no, setNo] = useState("")
    const [email, setEmail] = useState("")
    const [msg, setMsg] = useState("")

    const submitForm = () => {
        console.log(name, no, email, msg)
        if (name && no && email) {
            const mailtoLink = `mailto:enquiry@nayakstutorials.com?subject=Student requested a callback&body=Name: ${name}%0A
        Number: ${no}%0A
        Email: ${email}%0A
        Message: ${msg}%0A`;
            window.location.href = mailtoLink;

        setName("")
        setNo("")
        setEmail("")
        setMsg("")
        } else {
            alert("Please fill in all required fields before sending the email.");
        }
    }

    return (
        <>
            <div className='newenqmaindiv'>
                <div className='newenqbtn'>
                    <div className='newenqbtninner'>Enquiry Form</div>
                </div>

            </div>
            <div className='newenqformdiv'>
                <h3 className='enqheading'>Enquiry Form</h3>
                <form>
                    <div class="mb-3">
                        <input value={name} onChange={(e) => { setName(e.target.value) }} type="text" class="form-control-new" placeholder='Enter Name' />
                    </div>
                    <div class="mb-3">
                        <input value={no} onChange={(e) => { setNo(e.target.value) }} type="text" class="form-control-new" placeholder='Enter Phone' />
                    </div>
                    <div class="mb-3">
                        <input value={email} onChange={(e) => { setEmail(e.target.value) }} type="email" class="form-control-new" placeholder='Enter Email' />
                    </div>
                    <div class="form-floating">
                        <textarea value={msg} onChange={(e) => { setMsg(e.target.value) }} class="form-control-new" placeholder="Leave a comment here" rows="3"></textarea>
                    </div>
                    <button type="button" class="enqformBtn" onClick={submitForm}>Submit</button>
                </form>
            </div>
        </>
    )
}

export default Newenqire

