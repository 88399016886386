import React from 'react'
import Newnavbar from '../newNavbar/Newnavbar'
import LogoContact from '../logoContact/logoContact'
import Footer from '../footer/Footer'
import "./subeng.css";
import { Helmet } from "react-helmet";
import { FaWhatsapp } from "react-icons/fa";
import Newenqire from '../Enquire Form/Newenqire';

function Enginnertwo() {
    return (
        <>

            <Helmet>
                <meta charSet="utf-8" />
                <title>IIT JEE G-10 Plus Small Batch (Mains + Advanced) in Thane</title>
                {/* <title>{state==8?"saurabh":state==9?"rahul":state==10?"ketan":null}</title> */}
                <meta name="title" content="IIT JEE G-10 Plus Small Batch (Mains + Advanced) in Thane" />
                <meta name="description" content="Prepare for IIT JEE (Mains + Advanced) in Thane with our exclusive small batch of 10 students plus 2 scholars. A 2-year integrated classroom program for Class 11-12 (2025-27). Enroll now!" />
                <link rel="canonical" href="https://nayakstutorials.com/g-10-plus-batch-for-iit-jee-integrated/" />

            </Helmet>

            <div className="wpfloat">
                <LogoContact />
                <Newnavbar />


                <div className='mainsubengdiv'>
                    <div className='mainsubengdivinner'>
                        {/* <div style={{ marginBottom: "10px" }}><a href='/'>Home »</a><a href='/blog'>Our Offerings »</a><a>College »</a><a>Pre Engineering »</a><a>Engineering 1</a></div> */}
                        <h2>G-10 Plus Batch for IIT-JEE(M+A)</h2>
                        <p>2 Years Integrated Classroom Course For Classes 11-12 (2025-27)</p>
                    </div>
                </div>
                <div className='subengwhitediv'>
                    <h3>G-10 PLUS BATCH FOR IIT-JEE (Main + Advanced) + MHT-CET</h3>
                    <p className='subeng1line'>This is a ‘small batch’ concept to be formed only with 10 potential students plus 2 scholars.</p>
                    <p><b> This is a 2 years integrated course</b>, designed specifically for those students who are determined to succeed in the field of engineering & science. We provide you with handheld support, close attention, and mentoring in order to prepare well for the <b> IIT-JEE</b> exam and get high scores. Finally they get into one of  dream colleges like <b> IITs, NITs, IIITs, IISER, IISc</b> or other. <br></br><br></br>

                        Small batch advantage ensures enhanced learning with special attention, where every student in the batch gets noticed and cannot hide or be left behind. The mentors will spend more time even after the lecture sessions on practicing, self-studying, removing doubts, and providing maximum guidance and mentorship to students.<br></br><br></br>

                        Moreover, with the preparation of exam like IIT-JEE, Students also get prepared well for MHT-CET. </p>
                </div>

                <div className='subengbluedivmain'>
                    <div className='subengbluediv'>
                        <h3>Course Overview</h3>
                        <div className='subengblueflex'>
                            <div className='subengblue1'><p>For Classes</p></div>
                            <div className='subengblue2'><p>Along with Classes 11 – 12</p></div>
                        </div>
                        <div className='subengblueflex'>
                            <div className='subengblue1'><p>Course Code</p></div>
                            <div className='subengblue2'><p>1002</p></div>
                        </div>
                        <div className='subengblueflex'>
                            <div className='subengblue1'><p>For Academic Session</p></div>
                            <div className='subengblue2'><p>2025 – 2027</p></div>
                        </div>
                        <div className='subengblueflex'>
                            <div className='subengblue1'><p>Batch Type</p></div>
                            <div className='subengblue2'><p>A Group of only 10 + 2 Scholar students.</p></div>
                        </div>
                        <div className='subengblueflex'>
                            <div className='subengblue1'><p>Course Type</p></div>
                            <div className='subengblue2'><p>Integrated Course</p></div>
                        </div>
                        <div className='subengblueflex'>
                            <div className='subengblue1'><p>Lecture Language</p></div>
                            <div className='subengblue2'><p>Mixed (English / Hindi / Marathi)</p></div>
                        </div>
                        <div className='subengblueflex'>
                            <div className='subengblue1'><p>Study Materials, Tests & Notes</p></div>
                            <div className='subengblue2'><p>English Medium</p></div>
                        </div>
                        <div className='subengblueflex'>
                            <div className='subengblue1'><p>Board</p></div>
                            <div className='subengblue2'><p>CBSE, ICSE, Maharashtra Board</p></div>
                        </div>
                        <div className='subengblueflex'>
                            <div className='subengblue1'><p>Syllabus Coverage</p></div>
                            <div className='subengblue2'><p>IIT-JEE(Main + Advanced) + 11-12 School Board + Support for MHT-CET exam preparation</p></div>
                        </div>
                        <div className='subengblueflex'>
                            <div className='subengblue1'><p>Course Highlights</p></div>
                            <div className='subengblue2'>
                                {/* <li className='subli2'>Classes by ITians, NITians & Qualified Faculty Team</li>
                            <li className='subli2'>Regular Theory Classes (6 days a week).</li>
                            <li className='subli2'>Self-study & Practice Sessions At Center.</li>
                            <li className='subli2'>Regular Tests in the form of weekly, minor & major</li>
                            <li className='subli2'>Each Student will be assigned a mentor.</li>
                            <li className='subli2'>Utmost attention & performance tracking</li>
                            <li className='subli2'>Ensure no learning gaps.</li>
                            <li className='subli2'>Excellent mentoring by experts.</li>
                            <li className='subli2'>Regular updates to parents and more.</li> */}


                                <div className='subli2'>Classes by ITians, NITians & Qualified Faculty Team</div>
                                <div className='subli2'>Regular Theory Classes (6 days a week).</div>
                                <div className='subli2'>Self-study & Practice Sessions At Center.</div>
                                <div className='subli2'>Regular Tests in the form of weekly, minor & major</div>
                                <div className='subli2'>Each Student will be assigned a mentor.</div>
                                <div className='subli2'>Utmost attention & performance tracking</div>
                                <div className='subli2'>Ensure no learning gaps.</div>
                                <div className='subli2'>Excellent mentoring by experts.</div>
                                <div className='subli2'>Regular updates to parents and more.</div>



                            </div>
                        </div>
                        <div className='subengblueflex'>
                            <div className='subengblue1'><p>Subjects Covered </p></div>
                            <div className='subengblue2'><p>Physics, Chemistry & Math (As per NTA syllabus for JEE Main & JEE Advanced.)</p></div>
                        </div>
                        <div className='subengblueflex'>
                            <div className='subengblue1'><p>Admission Eligibility</p></div>
                            <div className='subengblue2'><p>Score in <b>taleNT2025</b> exam/ <b> NSAT</b> exam <b>/ Marks ≥ 70% </b> in 10th Board /<b> Past Academic</b> Performances.</p></div>
                        </div>
                        <div className='subengblueflex'>
                            <div className='subengblue1'><p>Admission Process</p></div>
                            <div className='subengblue2'><p>First come First Served Basis</p></div>
                        </div>
                        <div className='subengblueflex'>
                            <div className='subengblue1'><p>Scholarship</p></div>
                            <div className='subengblue2'><p>No Scholarship Applicable in G 10 Batch (Except for 2 scholars)</p></div>
                        </div>
                        <div className='subengblueflex'>
                            <div className='subengblue1'><p>Batches Start From </p></div>
                            <div className='subengblue2'><p>04-June-2025</p></div>
                        </div>
                        <div className='subengblueflexother'>
                            <p>Important:</p>
                            <div className='subli'><b>Course Fee Structure:</b> Total Course Fee has two parts. One is Admission cum Admin Fee (One Time & Non Refundable) and Second is Tuition Fee.</div>

                            <div className='subli'>
                                <b>Fee Mode: </b><b>i. Installment Mode:</b>  Two years full fee to be paid in 16 installments. <br></br>
                                <div><b>ii. Lumpsum Mode:</b> LUMPSUM Fee discount is additional 5% less on the applicable Tuition Fee.</div>
                            </div>

                            <div className='subli' style={{ display: 'block' }}> <b>First Installment:</b> In case of Installment fee scheme, first installment is to be paid at time of admission and rest installments are to be paid in subsequent months.</div>

                            <div className='subli'><b>GST: </b>Total Fee Amount is inclusive of GST @ 18% (Present Rate) and supply of Study Materials(English Medium).</div>

                            <div className='subli'><b>Fee: </b>Course Fee may be changed, if there would be any levy/change in taxes by the Central/State Govt.</div>

                            <div className='subli'><b>PDCs: </b>In the case of installment payment option, students have to deposit PDC (Post Dated Cheque) of balance amount at the time of first installment deposit.</div>

                            <div className='subli'><b>Seats:</b> Admission in a particular course at a particular location is subjected to availability of seats.</div>

                            <div className='subli'><b>Fee Receipt:</b> Keep your fee receipt safely, in many cases it will work as an authentic proof of your admission.</div>

                            <div className='subli'><b>Refund Policy:</b> Refund Application (In the standard Format) has to be applied and further the refund will be processed on the Tuition Fee paid as per the standard coaching guidelines.</div>
                        </div>

                    </div>
                </div>


                <Footer />

                <a href="https://wa.me/+917710010044" target="_blank"><div className="wpicon"><FaWhatsapp id='faWp' size={50} color='white' /></div></a>
                {/* <div className='newenquirediv'><Newenqire /></div> */}
            </div>
        </>
    )
}

export default Enginnertwo
